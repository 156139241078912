.cameraConfig {
  display: block;
  margin: auto;
  margin-top: 2%;
  width: 38vh;
  height: auto;
  border-radius: 22px;
  margin-bottom: 25px;
}

.proceedConfig {
  display: block;
  margin: auto;
  margin-top: 5%;
  width: 220px;
  height: 50px;
  border: 1px solid #d1d1d1;
  background: #03a300;
  border-radius: 31px;
  padding: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  color: #ffffff;
}

.heading {
  margin-top: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 1.5px;
}

.micCam {
  display: flex;
  margin: auto;
  align-items: center;
  margin-inline: 30px;
  margin-top: 3%;
  width: 410px;
  height: 58px;
  background: #464f67;
  box-shadow: 0px 0px 24px 9px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #ffffff;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}

/* .micButton {
  display: flex;
  align-items: center;
  margin: auto;
  margin-inline: 30px;
  margin-top: 3%;
  width: 410px;
  height: 58px;
  background: #464f67;
  box-shadow: 0px 0px 24px 9px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
} */

.headphone {
  padding: 10px;
}

.camButton {
  display: inline;
  min-width: 304px;
  border-radius: 12px;
  border-color: #464f67;
  max-width: 312px;
  padding-block: 11px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  background: #464f67;
  color: #ffffff;
  letter-spacing: 1px;
}

.camPic {
  margin-left: 15px;
}

/* .micText {
  display: flex;
  border-radius: 12px;
  min-width: 304px;
  border-color: #464f67;
  max-width: 312px;
  padding-block: 12px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  background: #464f67;
  color: #ffffff;
} */

.dropdown {
  display: flex;
  min-width: 304px;
  border-radius: 12px;
  border-color: #464f67;
  max-width: 312px;
  padding-block: 11px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  background: #464f67;
  color: #ffffff;
  letter-spacing: 1px;
}

.align {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
  text-align: -webkit-center;
  justify-content: center;
}

.camText {
  min-width: 304px;
  border-radius: 12px;
  border-color: #464f67;
  max-width: 312px;
  margin-right: 15px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  background: #464f67;
  color: #ffffff;
  letter-spacing: 1px;
}

.align {
  display: block;
  margin: auto;
  /* display: inline-block;
align-content: center; */
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  /* top: calc(50% - 356px / 2 - 85px); */
  /* margin-left: auto;
  margin-right: auto; */
}

@media screen and (min-width: 992px) {
  .cameraConfig {
    width: 100vh;
  }
}

@media screen and (min-width: 992px) {
  .proceedConfig {
    margin-top: 2%;
  }
}
