.loader-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 9999;
  display: flex;
}

.loader {
  text-align: center;
  justify-content: center;
}

.circle {
  margin: auto;
}
