.errorVideoWrapper {
  text-align: center;
  margin-top: 40px;
}

.errorGif {
  max-width: 65%;
  max-height: 65%;
}

.back {
  background: #00d1ff;
  border: 1px solid #d1d1d1;
  border-radius: 31px;
  font-size: large;
  font-weight: bold;
  color: #ffffff;
  margin: 20px;
  padding: 10px;
}
