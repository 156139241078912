.header {
  background: #010920;
  height: 90px;
  text-align: center;
}

.login_logo {
  display: none;
}

.login {
  justify-content: center;
  text-align: -webkit-center;
  margin: 15px;
}

.userlogin {
  display: block;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  text-align: center;
  color: #ffffff;
}

.formWrapper {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.user-details {
  padding: 10px;
  margin: 10px;
  border-radius: 12px;
  width: 400px;
  height: 60px;
  background: #464f67;
  border: 0px;
}

input::placeholder {
  height: 80px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #939393;
}

.input::placeholder {
  background: #939393;
}

.buttonWrapper {
  text-align: center;
}

.loginbutton {
  width: 200px;
  height: 70px;
  color: #ffffff;
  align-items: center;
  background: #002896;
  border: 1px solid #d1d1d1;
  border-radius: 31px;
}

.loginText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  align-items: center;
  text-align: center;
}

.h2 {
  color: #ffffff;
  font-weight: bold;
  font-family: 'Source Sans Pro';
  margin-bottom: 30px;
}

.buttondiv {
  display: flex;
  justify-content: space-around;
  width: 50%;
}
@media screen and (min-width: 992px) {
  .login_logo {
    width: 336px;
    height: 303px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .userlogin {
    margin-top: 15px;
  }
}
