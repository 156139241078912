.titleclass {
  display: flex;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  align-items: center;
}

.cards {
  width: 700px !important;
  border-radius: 16px !important;
  border: 2px solid hsl(191, 100%, 50%) !important;
  background: #010920 !important;
  font-family: 'Inter', sans-serif !important;
  height: 98px;
}

.title {
  color: white;
  font-size: 14px;
  font-family: 'Inter', sans-serif !important;
}

.test {
  font-family: 'Inter' !important;
}

.testico {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.expand {
  color: white;
  text-align: center !important;
}

.line {
  height: auto;
  width: 75%;
}

.flex-container {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 15px;
}
.college-text {
  font-size: 10px;
}

.card-item {
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.tomorrow-day-item {
  font-size: 10px;
  display: flex;
  align-items: center;
  background: rgba(0, 209, 255, 0.85);
  border-radius: 6px;
  padding: 2px 7px;
  width: 3.7rem;
  height: 1rem;
  justify-content: center;
}

.today-day-item {
  font-size: 10px;
  display: flex;
  align-items: center;
  background: #fc0d0d;
  border-radius: 6px;
  padding: 2px 7px;
  width: 3rem;
  height: 1rem;
  justify-content: center;
}
.polygon {
  position: absolute;
  left: 90%;
  top: 30%;
}
.polygon:hover {
  cursor: pointer;
}
.css-1rwjz6-MuiCardActions-root {
  padding-right: 8px;
}

.para {
  color: white;
  font-family: 'Inter', sans-serif;
}

.middlebutton {
  color: white !important;
  font-size: 12px !important;
  border: 2px solid #00d1ff !important;
  border-radius: 8px !important;
  height: 38px;
  font-family: 'Inter', sans-serif !important;
  background: transparent;
  width: 141px;
  font-weight: 500;
}

.middlebuttondiv {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  background: transparent !important;
  font-family: 'Inter', sans-serif !important;
}

.middlebutton2 {
  background-color: #16de66 !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  border-radius: 8px !important;
  height: 38px;
  width: 100px;
  font-family: 'Inter', sans-serif;
}

.datas {
  padding: 8px;
}

/* modal css */
.modals {
  background-color: #010920;
  width: 836px;
  border: 1px solid #00d1ff;
  border-radius: 20px;
  height: 450px;
  box-shadow: 0px 0px 147px -58px #939393;
}

.i {
  display: flex;
  justify-content: space-between;
}

.ico {
  margin-top: 1em;
}

.icon {
  width: 50px;
  height: auto;
}

.io {
  margin-left: 1.5em;
}

.oi {
  margin-right: 1em;
}

.contents {
  display: flex;
}

.sid {
  width: 50%;
  color: white;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.tesname {
  background: transparent;
  border: none;
  border-bottom: 1px solid #645d5d;
  height: 23px;
}

.subject {
  background: transparent;
  border: none;
  border-bottom: 1px solid #645d5d;
  height: 18px;
  width: 40%;
}

.detai ::placeholder {
  font-size: 13px !important;
}

.detai {
  margin-left: 2em;
}

.modalbutto {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  width: 100%;
}

.butt {
  font-size: 16px !important;
  margin-right: 4em;
  height: 45px !important;
}

.sche {
  background: #16de66 !important;
  height: 45px !important;
}

@media (max-width: 868px) and (min-width: 400px) {
  .cards {
    width: 500px !important;
  }
}
