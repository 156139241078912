.announcements {
  width: 231px;
  height: 45px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  margin-left: 111px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.announcement {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
}
.announcement-text {
  font-size: 36px;
  font-weight: 600;
}

.announcementCard {
  box-sizing: border-box;
  width: 410px;
  height: 98px;
  background: #010920;
  border: 1px solid #00d1ff;
  box-shadow: 0px 0px 147px -58px #939393;
  border-radius: 23px;
  color: #ffffff;
}

.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border: 2px solid #00d1ff;
}

.myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: 2px solid #00d1ff;
}
.text {
  position: relative;
  margin-top: 16px;
  margin-bottom: 57px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}
