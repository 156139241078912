.ques_body {
  overflow: auto;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.options {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  margin: 25px;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
}

.rollNumber {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.04em;
  color: rgba(255, 255, 255, 0.2);
  height: 225px;
  float: left;
  transform: translateY(50%);
  writing-mode: vertical-lr;
  transform-origin: 0 0;
}

.question_background {
  background: #010920;
  border-radius: 16px;
  margin: 12px;
  overflow: auto;
  display: block;
}

.question {
  margin: 12px;
  padding: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.check {
  width: 24px;
  height: 24px;
  background: #000000;
  opacity: 0;
}

.box {
  width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  display: inline-block;
}

.check:checked {
  opacity: 0.7;
}

.markForReview {
  width: 235.2px;
  height: 31.75px;
  margin-left: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
}

.navbtn {
  display: flex;
}

.previous {
  box-sizing: border-box;
  width: 117px;
  height: 49px;
  background: #232732;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 9px;
  margin: 25px;
}

.previous:hover {
  border: 2px solid#00D1FF;
  color: #00d1ff;
}

.next {
  box-sizing: border-box;
  width: 84px;
  height: 49px;
  background: #232732;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 9px;
  margin: 25px;
}

.next:hover {
  border: 2px solid#00D1FF;
  color: #00d1ff;
}

.cameraBox {
  height: 125px;
  background: #141c32;
  right: 0;
  bottom: 0;
  text-align: right;
  position: fixed;
}

.MuiList-root {
  background-color: #232732;
  color: #ffffff;
}

.voidBox {
  height: 150px;
}

.res-navigate {
  display: none;
}

@media screen and (max-width: 991px) {
  .ques_body {
    background: #010920;
    border-radius: 16px;
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .question_background {
    padding: 5px;
  }

  .question {
    background: #010920;
    height: 60vh;
    border-radius: 16px;
    margin-top: 12px;
    float: left;
  }

  .markForReview {
    display: inline;
    width: max-content;
    width: 115px;
  }

  .res-navigate {
    justify-content: space-evenly;
    display: inline;
  }

  .navigate {
    display: none;
  }

  .navbtn {
    justify-content: center;
    display: inline-flex;
    display: inline-flex;
  }
}
