.exitPage {
  text-align: -webkit-center;
}

.exitBody {
  margin: 30px;
  padding: 40px;
  background: #010920;
  border-radius: 33px;
}

.exitText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
}

.circle-loader {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border-color: green;
  margin-top: 25px;
  background: #03a300;
}

.checkmark {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
  opacity: 1;
  height: 60px;
  width: 30px;
  border-right: 8px solid #ffffff;
  border-top: 8px solid #ffffff;
  margin-top: 10px;
}

.responseBtn {
  display: inline-block;
  justify-content: space-evenly;
}

.sameTraits {
  border-radius: 14px;
  padding: 5px;
  height: 60px;
  width: 300px;
  color: #ffffff;
  margin: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  align-items: center;
  text-align: center;
}

.viewScore {
  background: #00d1ff;
}

.responses {
  background: #00d1ff;
}

.exit {
  background: #fc0d0d;
}

.score {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 22px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.score.active {
  display: block;
  margin: 20px;
}

.not-active {
  display: none;
}

.scoreRed {
  background: #fc0d0d;
}

.scoreYellow {
  background: #f8a825;
}

.scoreGreen {
  background: #03a300;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 4px;
    opacity: 1;
  }
  40% {
    height: 16px;
    width: 8px;
    opacity: 1;
  }
  100% {
    height: 40px;
    width: 20px;
    opacity: 1;
  }
}
