* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: #141c32;
}

a {
  color: unset;
  text-decoration: none;
}

.site {
  overflow-y: hidden;
}

.modal {
  background-color: #464f67;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 574px;
  height: 320px;
  border-radius: 34px;
  font-size: 25px;
}

.modalbutton {
  width: 110px;
  height: 32px;
  background: rgba(196, 196, 196, 0.3);
  border-radius: 10px;
  color: #ffffff;
}
.modalbutton2 {
  width: 110px;
  height: 32px;
  background: #010920;
  border-radius: 10px;
  color: #ffffff;
  border: 1px solid #00d1ff;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (min-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .modal {
    padding: 15px;
    margin: 20px;
    min-width: 200px;
    min-height: 200px;
    max-width: 574px;
    max-height: 320px;
    width: initial;
    height: initial;
  }
}
