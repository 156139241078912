@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.searchInputs {
  background-color: #010920;
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  padding: 7px 2px;
  gap: 5px;
  border-radius: 31px;
  width: 78%;
}
.searchdiv {
  margin-bottom: 6%;
  margin-left: 8px;
}

.search input {
  background-color: #010920;
  width: 75%;
  color: #c4c4c4;
  border: none;
  font-family: 'Inter';
}
.searchicon {
  color: white;
  padding: 0px !important;
}
.icondiv {
  background-color: #00d1ff;
  height: 50px;
  border-right: 2px solid #ffffff;
  border-radius: 0px 10px 10px 0px;
}
::-webkit-input-placeholder {
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left !important;
  font-family: 'Inter' !important;
  font-style: italic !important;
  height: 100%;
}
.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 5px;
}

input:focus {
  outline: none;
}
.dataResult {
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

a:hover {
  background-color: lightgrey;
}

#clearBtn {
  cursor: pointer;
}
.grid-container {
  display: grid;
  grid-template-columns: 40% 60%;
  margin-top: 55px;
  padding-right: 20px;
}
.rightside {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin: auto;
  max-width: 1450px;
}
.upcard {
  margin: 10px;
  display: flex;
  position: relative;
}
.mediacards {
  max-height: 60vh;
  overflow-y: scroll;
  /* width: 440px; */
}

/* .grid-filtered-data{
  border:2px solid red;
} */
.text-icon-flex-container {
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.icon {
  padding: 15px 10px;
}
.icon:hover {
  cursor: pointer;
}
.test-knowledge {
  font-weight: 600;
  margin: 5px;
  text-align: left;
  /* border:2px solid red; */
  border-bottom: 2px solid #645d5d;
  color: white;
  font-weight: 600;
  font-size: 32px;
  font-family: 'Inter';
  line-height: 48.41px;

  width: 50%;
  padding: 5px;
  margin-left: 10px;
}

@media (max-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 100%;
  }
  .search input {
    width: 190px;
  }
  .rightside {
    grid-template-columns: auto;
    flex-direction: column;
    justify-content: center;
    height: auto;
    align-items: center;
  }
}
@media (max-width: 1049px) and (min-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 100%;
  }
  .app {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  .rightside {
    grid-template-columns: auto auto;
    justify-content: space-around;
  }
  .calendar-container {
    /* width: auto !important; */
    width: 100%;
  }
}

/* @media (max-width: 1450px) and (min-width: 1050px) {
  .rightside {
    grid-template-columns: auto auto;
    justify-content: space-around;
  }
  .mediacards {
    max-height: 60vh;
    overflow-y: scroll;
    width: 355px !important ;
  }
} */
@media (max-width: 868px) and (min-width: 400px) {
  .searchdiv {
    width: 43%;
  }
  .test-knowledge {
    font-size: 25px;
    padding: 10px;
    line-height: 20px;
  }
}
.nothingFound {
  background: #010920;
  border-radius: 31px;
  padding: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  color: #ffffff;

  display: flex;
  align-items: center;
}
