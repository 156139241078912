.react-calendar {
  box-sizing: border-box;
  max-width: 415px;
  width: 75%;
  height: 380px;
  background: #010920;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 20px;
  box-shadow: 0px 0px 147px -58px #939393;
  padding-left: 35px;
  padding-right: 35px;

  margin: auto;
}

.react-calendar__navigation {
  display: flex;
}

.react-calendar__navigation__label {
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.7;
}
.react-calendar__month-view__days__day--weekend {
  color: #dfdfdf;
}

.vectorCalendar {
  display: flex;
  width: 30px;
  height: auto;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  color: #ffff;
  border-radius: 50%;
  font-size: 14px;
}
̦button:enabled:hover {
  cursor: pointer;
  /* background-color: #7d7d7b; */
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  text-align: center;
  padding-top: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: none;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  color: #ffff;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 1.1em 0.5em;
  background: none;
  color: #ffff;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: #949494; */
}

.react-calendar__tile--now {
  background: #525252;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #a5a59e;
}

.react-calendar__tile--hasActive {
  background: #006edc;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
  text-shadow: 0.5px 0.5px black;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.calendar-container {
  width: 100%;
  margin-top: 10px;

  /* margin-right:70px; */
  /* margin-left:-10px; */
}

.calendar-container .announcement {
  width: 100%;
  margin-top: 20px;
  font-family: 'Source Sans Pro';
  text-align: center;
  color: white;
}

.mediacards {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 415px;
  margin: auto;
  height: 500px;
}

.announc {
  border: 3px solid #00d1ff;
  background: #010920;
  margin-bottom: 1em;
  border-radius: 23px !important;
  border: 3px solid #00d1ff !important;
  margin-left: 22px !important;
  background: #010920 !important;
}

.subjec {
  background: transparent;
  border: none;
  border-bottom: 1px solid #645d5d;
  height: 18px;
  width: 20% !important;
  color: white;
  letter-spacing: 1px !important;
  margin-top: 10%;
  font-family: Garamond;
  /* margin: 8px; */
}

.head {
  background: transparent;
  border: none;
  border-bottom: 1px solid #645d5d;
  height: 18px;
  width: 70%;
  color: white;
  font-size: 15px;
  font-style: italic;
  letter-spacing: 1px;
  margin: 19px;
}

.detai ::placeholder {
  font-size: 13px !important;
}

.add {
  background-color: #16de66;
  font-weight: 600;
  color: white;
  border-radius: 7px;
  width: 55px;
  height: 31px;
}

.adddiv {
  display: flex;
  justify-content: right;
  width: 90%;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

@media (max-width: 600px) {
  .mediacards {
    display: none;
  }

  .calendar-container {
    display: none;
  }
}

@media (max-width: 1450px) and (min-width: 1050px) {
  .react-calendar {
    /* max-width: 320px; */
  }
}
