.bubbleBackground {
  background: #232732;
  border-radius: 16px;
  justify-content: space-between;
  overflow: auto;
  white-space: nowrap;
  margin: 20px;
  padding: 15px;
  display: flex;
}

.bubbleBox {
  /* margin-right: 33px;
  margin: 20px;
  padding: 15px;
  margin-top: 185px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff; */
}

.circle {
  border: 0px solid;
  border-radius: 50%;
  border-color: #ffffff;
  background: #010920;
  color: white;
  padding: 2px;
  width: 35px;
  height: 35px;
  line-height: 31px;
  text-align: center;
  margin-right: 5px;
}

.answered {
  box-shadow: inset 0px 0px 8px 2px #00ff66;
}

.markedreview {
  box-shadow: inset 0px 0px 8px 2px #bd00ff;
}

.visited {
  /*border-color: #010920;*/
  box-shadow: inset 0px 0px 1px 1px #ff0000;
}

.unvisited {
  box-shadow: inset 0px 0px 8px 2px #ffffff;
}

.current {
  color: #010920;
  border-color: #ffffff;
  background: #ffffff;
}
