.titleclass {
  display: flex;
  justify-content: space-between;
}

.cardd {
  /* width: 410px !important; */
  border-radius: 23px !important;
  border: 2px solid #00d1ff !important;
  margin: 22px !important;
  background: #010920 !important;
}
.icon-text {
  display: flex;
  gap: 10px;
  align-items: center;
}
.title {
  color: white;
  font-size: 14px;
}

@media (max-width: 1450px) and (min-width: 1050px) {
  .cardd {
    /* width: 320px !important; */
  }
}
