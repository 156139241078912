.topCam {
  width: 41px;
  height: 41px;
  margin-right: 15px;
  border-radius: 50px;
  vertical-align: sub;
}

.name {
  display: none;
}

.personal_details {
  background: #010920;
  outline: none;
  border: 0px;
  margin-top: 13px;
  margin-right: 10px;
  padding: 5px;
}

.personal_details.active,
.personal_details:hover {
  border: 2px solid #00d1ff;
  border-radius: 20px;
  margin-right: 8px;
  margin-top: 11px;
}

.account {
  display: block;
  right: 0;
  z-index: 1;
  width: 300px;
  text-align: left;
  background: #010920;
  border-radius: 0px 0px 33px 33px;
  position: fixed;
  top: 5em;
}

/* .active-class{
    margin-top:20px;
    margin-right:50px;
    margin-left:20px;
    margin-bottom: 5px;
    text-align: left;
    padding: 5px;
    width:250px;
    background: #464F67;
    border-radius: 10px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    border:0px;
  } */

.non_active {
  /* display: flex; */
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 20px !important;
  padding: 5px !important;
  font-family: 'Source Sans Pro';
  font-style: normal;
  background: #010920;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  border: 2px solid transparent;
}

.non_active:hover {
  background: #464f67;
  width: 250px;
  text-align: left;
  border-radius: 10px;
  border: 2px solid;
  margin-top: 3px;
  margin-left: 18px;
  margin-bottom: 3px;
}

.vector {
  width: 40px;
  border-left: 3px solid transparent;
  z-index: 1000;
  position: relative;
  left: -22px;
  display: inline;
}

.non_active:hover .vector {
  border-left: 3px solid cyan;
}

.icons {
  margin-top: 5px !important;
  vertical-align: sub !important;
}

.settings {
  margin-bottom: 10px;
}

.settings:hover {
  margin-bottom: 8px;
}

.logout {
  margin-top: 5px;
  margin-bottom: 15px;
}

.account_text {
  margin: 5px;
  padding: 5px;
}

.horizontal_bar {
  width: 250px;
  margin-top: 5px;
  margin-left: 20px;
  border: 0.5px solid #c4c4c4;
  transform: rotate(0.01deg);
}

.sign-in-button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 39px;
  color: #ffffff;
  margin-right: 10px;
  vertical-align: super;
}
@media screen and (min-width: 992px) {
  .name {
    display: inline;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 39px;
    color: #ffffff;
    margin-right: 10px;
    vertical-align: super;
  }
}
